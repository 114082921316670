<template>
    <DashboardContainer>
        <Services/>
    </DashboardContainer>
</template>

<script>
import DashboardContainer from '../containers/DashboardContainer'
import Services from '../components/services/ServicesInfo'

export default {
    name: 'servicesinfo',
    beforeCreate: function () {  
        var auth = this.$storage.get('auth') 
        if (!auth) {
            this.$router.push('/login')
        }else{
            if(!auth.token){
                this.$router.push('/login')
            }
        }
    },
    components: {
        DashboardContainer,
        Services
    }
}
</script>